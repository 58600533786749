/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    displayport: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 7a.5.5 0 00-.5.5v1a.5.5 0 001 0V8h10v.5a.5.5 0 001 0v-1a.5.5 0 00-.5-.5z"/><path pid="1" d="M1 5a1 1 0 00-1 1v3.191a1 1 0 00.553.894l1.618.81a1 1 0 00.447.105H15a1 1 0 001-1V6a1 1 0 00-1-1zm0 1h14v4H2.618L1 9.191z"/>',
    },
});
